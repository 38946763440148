import i18n from './i18n';

export class LoginTexts {
    private constructor() { }
    static get login() { return i18n.t(`login.${i18n.language}`) }
    static get userName() { return i18n.t(`userName.${i18n.language}`) }
    static get password() { return i18n.t(`password.${i18n.language}`) }
    static get usePortalDates() { return i18n.t(`usePortalDates.${i18n.language}`) }
    static get signIn() { return i18n.t(`signIn.${i18n.language}`) }
    static get imprint() { return i18n.t(`imprint.${i18n.language}`) }
}

export class ManufacturerTexts {
    private constructor() { }
    static get chooseManufacturer() { return i18n.t(`chooseManufacturer.${i18n.language}`) }
}

export class UploadTexts {
    private constructor() { }
    static get datamatrixOrQrScannen() { return i18n.t(`datamatrixOrQrScannen.${i18n.language}`) }
    static get scan() { return i18n.t(`scan.${i18n.language}`) }
    static get inputSerialNumberOrIdLink() { return i18n.t(`inputSerialNumberOrIdLink.${i18n.language}`) }
    static get goon() { return i18n.t(`goon.${i18n.language}`) }
    static get qrCodeScannen() { return i18n.t(`qrCodeScannen.${i18n.language}`) }
    static get inputIdLink() { return i18n.t(`inputIdLink.${i18n.language}`) }
    static get saveInConexo() { return i18n.t(`saveInConexo.${i18n.language}`) }
    static get qrCodeScannenAri() { return i18n.t(`qrCodeScannenAri.${i18n.language}`) }
    static get qrCodeScannenBray() { return i18n.t(`qrCodeScannenBray.${i18n.language}`) }
    static get inputIdLinkForConexo() { return i18n.t(`inputIdLinkForConexo.${i18n.language}`) }
    static get datamatrixOrIdLink() { return i18n.t(`datamatrixOrIdLink`) }
}

export class ErrorTexts {
    private constructor() { }
    static get error() { return i18n.t(`error.${i18n.language}`) }
    static get sthWrong() { return i18n.t(`sthWrong.${i18n.language}`) }
    static get wrongPasswort() { return i18n.t(`wrongPasswort.${i18n.language}`) }
    static get unexpectedError() { return i18n.t(`unexpectedError.${i18n.language}`) }
    static get errorWrongCredentials() { return i18n.t(`errorWrongCredentials.${i18n.language}`) }
    static get serverNotAvailable() { return i18n.t(`serverNotAvailable.${i18n.language}`) }
    static get wrongFormat() { return i18n.t(`wrongFormat.${i18n.language}`) }
    static get unacceptableUrl() { return i18n.t(`unacceptableUrl.${i18n.language}`) }
}

export class GeneralTexts {
    private constructor() { }
    static get newLogin() { return i18n.t(`newLogin.${i18n.language}`) }
    static get sessionInfo() { return i18n.t(`sessionInfo.${i18n.language}`) }
    static get customWarning() { return i18n.t(`customWarning.${i18n.language}`) }
    static get closeCamera() { return i18n.t(`closeCamera.${i18n.language}`) }
    static get authorizationWarning() { return i18n.t(`authorizationWarning.${i18n.language}`) }
    static get dataSecurity() { return i18n.t(`dataSecurity.${i18n.language}`) }
    static get serialNumber() { return i18n.t(`serialNumber.${i18n.language}`) }
    static get successful() { return i18n.t(`successful.${i18n.language}`) }
}
