import Rive from '@rive-app/react-canvas';
import { useEffect, useState } from 'react';
import check from "../../assets/check.riv";
import './RiveComponent.scss'

export default function RiveContainer() {
    const [isPlaying, setIsPlaying] = useState(true);

    // animation plays 1.6 seconds after that it should be stopped
    useEffect(() => {
        setTimeout(() => {
            setIsPlaying(false);
        }, 1600)
    }, []);

    // stateMachines={'auto'}, this parameter stops the animation
    return (
        <div data-testid='success_animation'>
            {
                !isPlaying ?
                    <Rive
                        src={check}
                        className='rive_container'
                        stateMachines={'auto'}
                    /> :
                    <Rive
                        src={check}
                        className='rive_container'
                    />
            }
        </div>

    );
}