export enum RequestPaths {
    loginPath = '/api/restAPI/v1/login',
    netilionIdCheck = '/api/netilionIdCheck',
    saveInConexoPathNetilion = '/api/savenetilion',
    saveInConexoPathAri = '/api/saveari',
    saveInConexoPathBray = '/api/saveBray',
    saveInConexoPathKrohne = '/api/savekrohne',
    getPermissions = '/api/getpermissions',
    getBackendLoginInfo = '/api/getBackendLoginInfo',
    getBackendGeneralInfo = '/api/getBackendGeneralInfo',
    impressumPath = '/navigation/footerMinimalImprintView'
}