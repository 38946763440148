import { UploadTexts } from '../../../language/Texts';
import { INetilionResponse } from '../../../models/INetilionResponse';
import { RequestPaths } from '../../../models/RequestPaths';
import { BackendResult } from '../../../utils/BackendResult';
import BoxComponent from '../../BoxComponent/BoxComponent';

interface ConexoUploadNetilionProps {
    netilionResponse: INetilionResponse,
    allowedNetilionUrls: string | undefined,
    setConexoResponse: (item: BackendResult | null) => void,
    loading: boolean,
    setLoading: (item: boolean) => void
}
const ConexoUploadNetilion = ({ netilionResponse, setConexoResponse, setLoading, loading, allowedNetilionUrls }: ConexoUploadNetilionProps) => {
    const url = RequestPaths.saveInConexoPathNetilion

    return (
        <>
            {netilionResponse?.isSuccess === true &&
                <BoxComponent
                    boxTitle={UploadTexts.qrCodeScannen}
                    setConexoResponse={setConexoResponse}
                    inputType='url'
                    placeholder={UploadTexts.inputIdLink}
                    buttonTex={UploadTexts.saveInConexo}
                    setLoading={setLoading}
                    loading={loading}
                    requestUrl={url}
                    isConexoUploadNetilion={true}
                    allowedNetilionUrls={allowedNetilionUrls}
                />
            }
        </>
    )
}

export default ConexoUploadNetilion