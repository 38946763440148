import { UploadTexts } from '../../../language/Texts';
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { RequestPaths } from '../../../models/RequestPaths';
import BoxComponent from '../../BoxComponent/BoxComponent';

const ConexoUploadBray = ({ manufacturer, setConexoResponse, setLoading, loading }) => {
    const url = RequestPaths.saveInConexoPathBray

    return (
        <>
            {manufacturer === GeneralAttributes.bray &&
                <BoxComponent
                    boxTitle={UploadTexts.qrCodeScannenBray}
                    setConexoResponse={setConexoResponse}
                    inputType='url'
                    placeholder={UploadTexts.inputIdLink}
                    buttonTex={UploadTexts.saveInConexo}
                    setLoading={setLoading}
                    loading={loading}
                    requestUrl={url}
                />
            }
        </>
    )
}

export default ConexoUploadBray