import { GeneralTexts, LoginTexts } from '../../language/Texts'
import { RequestPaths } from '../../models/RequestPaths'
import './ImpirintDatasecurity.scss'

function ImpirintDatasecurity({ baseUrl }) {

    const getBaseUrl = () => {
        if (sessionStorage.getItem('baseUrl')) {
            return sessionStorage.getItem('baseUrl') + RequestPaths.impressumPath
        } else if (baseUrl) {
            return baseUrl + RequestPaths.impressumPath
        } else {
            return '#'
        }
    }
    return (
        <div className="impirint">
            <a className='impirint_link'
                data-testid="data_security"
                href=" https://www.inevvo-solutions.com/de/datenschutz-portal/"
                target="_blank"
            >{GeneralTexts.dataSecurity}</a>
            &ensp; | &ensp;
            <a className='impirint_link' href={"//" + getBaseUrl()} target="_blank">{LoginTexts.imprint}</a>
        </div>
    )
}

export default ImpirintDatasecurity
