import { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer';
import { IdleTimerEvents } from "../../models/IdleTimerEvent";
import { INetilionResponse } from "../../models/INetilionResponse";
import { IPermissionLists } from "../../models/IPermissionLists";
import { RequestPaths } from "../../models/RequestPaths";
import { BackendResult } from "../../utils/BackendResult";
import { HttpRequest } from "../../utils/HttpRequest";
import { PermissionCheck } from "../../utils/PermissionCheck";
import Loading from "../Loading/Loading";
import NoPermissionInfo from "../NoPermissionInfo/NoPermissionInfo";
import UnexpectedError from "../UnexpectedError/UnexpectedError";
import ConexoResponse from "./ConexoResponse/ConexoResponse";
import ConexoUploadAri from "./ConexoUploadAri/ConexoUploadAri";
import ConexoUploadKrohne from "./ConexoUploadKrohne/ConexoUploadKrohne";
import ConexoUploadNetilion from "./ConexoUploadNetilion/ConexoUploadNetilion";
import Manufacturer from "./Manufacturer/Manufacturer";
import NetilionResponse from "./NetilionResponse/NetilionResponse";
import NetilionUpload from "./NetilionUpload/NetilionUpload";
import "./Main.scss";
import ConexoUploadBray from "./ConexoUploadBray/ConexoUploadBray";

interface MainProps {
    setIsModalOpen: (isOpen: boolean) => void
}

const Main = ({ setIsModalOpen }: MainProps) => {
    const [netilionResponse, setNetilionResponse] = useState<INetilionResponse>();
    const [conexoResponse, setConexoResponse] = useState<BackendResult | null>();
    const [manufacturer, setManufacturer] = useState("");
    const [hasPermission, setHasPermission] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false)
    const [unexpectedError, setUnexpectedError] = useState<boolean>(false)
    const [selectableManufacturers, setSelectableManufacturers] = useState<string>()
    const [allowedNetilionUrls, setAllowedNetilionUrls] = useState<string>()

    const getPermissions = async () => {
        const url: string = RequestPaths.getPermissions
        try {
            const permissionLists = await new HttpRequest().getPermissions(url) as unknown as IPermissionLists
            setHasPermission(PermissionCheck.check(permissionLists))
        } catch (error) {
            setUnexpectedError(true)
        }
    }
    
    const getBackendGeneralInfo = async () => {
        try {
            const { selectableManufacturers, allowedNetilionUrls } = await new HttpRequest().getBackendInfo(RequestPaths.getBackendGeneralInfo)
            setSelectableManufacturers(selectableManufacturers)
            setAllowedNetilionUrls(allowedNetilionUrls)

            sessionStorage.setItem('selectableManufacturers', selectableManufacturers)
            sessionStorage.setItem('allowedNetilionUrls', allowedNetilionUrls)
        } catch (error) {
            console.log("Error on getting version number from backend :", error)
        }
    }

    useEffect(() => {
        getPermissions()
        setConexoResponse(null)
    }, [netilionResponse]);

    useEffect(() => {
        if (!sessionStorage.getItem('selectableManufacturers') || !sessionStorage.getItem('allowedNetilionUrls'))
            getBackendGeneralInfo()
    }, [])

    const permissonView = () => {
        return (
            <div className="permission_view">
                {hasPermission === undefined ? <Loading /> :
                    <NoPermissionInfo />}
            </div>
        )
    }
    const unexpectedErrorView = () => {
        return (
            <UnexpectedError />
        )
    }

    const mainView = () => {
        return (
            <div className="form-wrapper">
                <Manufacturer
                    selectableManufacturers={selectableManufacturers}
                    setManufacturer={setManufacturer}
                    setNetilionResponse={setNetilionResponse}
                    loading={loading}
                />
                <NetilionUpload
                    setNetilionResponse={setNetilionResponse}
                    setConexoResponse={setConexoResponse}
                    manufacturer={manufacturer}
                    setLoading={setLoading}
                    loading={loading}
                />
                <NetilionResponse
                    netilionResponse={netilionResponse!}
                    loading={loading}
                    manufacturer={manufacturer}
                />
                <ConexoUploadNetilion
                    netilionResponse={netilionResponse!}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                    allowedNetilionUrls={allowedNetilionUrls}
                />
                <ConexoUploadAri
                    manufacturer={manufacturer}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                />
                <ConexoUploadKrohne
                    manufacturer={manufacturer}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                />
                <ConexoUploadBray
                    manufacturer={manufacturer}
                    setConexoResponse={setConexoResponse}
                    setLoading={setLoading}
                    loading={loading}
                />
                <ConexoResponse
                    netilionResponse={netilionResponse!}
                    conexoResponse={conexoResponse!}
                    loading={loading}
                    manufacturer={manufacturer}
                />
            </div>
        )
    }

    const setView = () => {
        if (unexpectedError) {
            return unexpectedErrorView()
        } else if (hasPermission) {
            return mainView()
        } else {
            return permissonView()
        }
    }

    /******* TIMER *******/
    // if the user is not active, popup is displayed
    const onIdle = () => {
        sessionStorage.removeItem('isLoggedIn')
        setIsModalOpen(true)
    }
    // In order to set the time , that user can be inactive
    // events can be configured from IdleTimerEvents
    const { } = useIdleTimer({
        onIdle,
        timeout: 50 * 60 * 1000,
        events: IdleTimerEvents,
        debounce: 500,
    })
    /******* TIMER *******/

    return (
        <div className="main-wrapper">
            {setView()}
        </div>
    );
};

export default Main;
