import { useState } from 'react';
import { ErrorTexts, UploadTexts } from '../../../language/Texts';
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { INetilionResponse } from '../../../models/INetilionResponse';
import { RequestPaths } from '../../../models/RequestPaths';
import { BackendResult } from '../../../utils/BackendResult';
import { HttpRequest } from '../../../utils/HttpRequest';
import { Utils } from '../../../utils/Utils';
import BoxComponent from '../../BoxComponent/BoxComponent';


interface NetilionUploadProps {
    setNetilionResponse: (item: INetilionResponse) => void,
    setConexoResponse: (item: BackendResult) => void,
    setLoading: (item: boolean) => void,
    manufacturer: string,
    loading: boolean
}
const NetilionUpload = ({ setNetilionResponse, setConexoResponse, manufacturer, setLoading, loading }: NetilionUploadProps) => {
    const [inputValue, setInputValue] = useState('')

    const formSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            let result = await new HttpRequest().getFromNetilion(inputValue)
            successHandling(result)
        } catch (error) {
            errorHandling(error)
        }
        setLoading(false)
        setInputValue('')
    };

    const successHandling = (result: BackendResult) => {
        if (result.success) {
            setNetilionResponse({
                manufacturer: manufacturer,
                serialNumber: inputValue,
                isSuccess: true,
            })
        } else {
            console.log('error', result);
            setNetilionResponse({
                serialNumber: inputValue,
                isSuccess: false,
                message: result.error
            })
        }
    }

    const errorHandling = (error) => {
        console.log('error', error);
        setNetilionResponse({
            serialNumber: inputValue,
            isSuccess: false,
            message: error?.data ? Utils.arrangeErrorMessages(error.data) : ErrorTexts.unexpectedError
        })
    }

    return (
        <>
            {manufacturer === GeneralAttributes.netilion &&
                <BoxComponent
                    boxTitle={UploadTexts.datamatrixOrQrScannen}
                    formSubmit={formSubmit}
                    setNetilionInput={setInputValue}
                    netilionInput={inputValue}
                    setConexoResponse={setConexoResponse}
                    inputType='text'
                    placeholder={UploadTexts.inputSerialNumberOrIdLink}
                    buttonTex={UploadTexts.goon}
                    setLoading={setLoading}
                    loading={loading}
                    requestUrl={RequestPaths.netilionIdCheck}
                    setNetilionResponse={setNetilionResponse}
                />
            }
        </>
    )
}

export default NetilionUpload