import { useState } from 'react';
import { Alert, Button } from "react-bootstrap";
import { ErrorTexts, GeneralTexts, LoginTexts } from '../../language/Texts';
import { HttpRequest } from '../../utils/HttpRequest';
import ImpressumDatasecurity from '../ImpirintDatasecurity/ImpirintDatasecurity';
import './Login.scss';

const Login = ({ setIsLoggedIn, baseUrl }) => {

  const [alertOpt, setAlertOpt] = useState({ show: false, message: '' })
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const loginData = { username: username, password: password }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await new HttpRequest().login(loginData)
      successHandling()
    } catch (error) {
      console.log(error)
      errorHandling(error)
    }
  };

  const successHandling = () => {
    sessionStorage.setItem('isLoggedIn', 'true')
    sessionStorage.setItem('username', username)
    setIsLoggedIn(true)
  }

  const errorHandling = (error) => {
    setPassword('')
    if (error?.response?.status >= 500) {
      setAlertOpt({ show: true, message: ErrorTexts.serverNotAvailable })
    } else if (error?.response?.status === 401) {
      setAlertOpt({ show: true, message: ErrorTexts.errorWrongCredentials })
    } else {
      setAlertOpt({ show: true, message: ErrorTexts.unexpectedError })
    }
    setTimeout(() => {
      setAlertOpt({ show: false, message: '' })
    }, 5000)
  }

  return (
    <div className="login-wrapper">
      <Alert className="alert" variant="warning" show={alertOpt.show} >
        {alertOpt.message}
      </Alert>
      <p className="login-header">{LoginTexts.login}</p>
      <div className="register-form">
        <form data-testid="form" onSubmit={handleSubmit} className='login-form'>
          <div className="form-group">
            <input
              onInvalid={e => (e.target as HTMLInputElement).setCustomValidity(GeneralTexts.customWarning)}
              onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
              value={username}
              required={true}
              type="text"
              placeholder={LoginTexts.userName}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              data-testid="name-input"
            />
          </div>

          <div className="form-group">
            <input
              onInvalid={e => (e.target as HTMLInputElement).setCustomValidity(GeneralTexts.customWarning)}
              onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
              required={true}
              type="password"
              value={password}
              placeholder={LoginTexts.password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              data-testid="password-input"
            />
          </div>
          <div className="form-group">
            <p className="form-text">{LoginTexts.usePortalDates}</p>
          </div>

          <div className="form-group">
            <Button data-testid="submit-button" type="submit" className="custom-btn">
              {LoginTexts.signIn}
            </Button>
          </div>
        </form>
      </div>

      <div className="impirint_data">
        <ImpressumDatasecurity
          baseUrl={baseUrl} />
      </div>

    </div>
  );
};

export default Login;